import React from 'react';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

const override = css`
    display: block;
    margin: 0 auto;
`;

const LoadingComponent = () => (
  <div className="sweet-loading">
    <ClipLoader
      css={override}
      sizeUnit="px"
      size={80}
      color="#3f4d67"
    />
  </div>
);

export default LoadingComponent;
