import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { composeWithDevTools } from 'redux-devtools-extension';

import Loadable from 'react-loadable';
import * as serviceWorker from './serviceWorker';
import reducer from './store/reducer';
import config from './config';
import PrivateRoute from './PrivateRoute';
import Loader from './App/layout/Loader';
import SignIn1 from './Demo/Authentication/SignIn/SignIn1';
import CallbackComponent from './App/components/CallbackComponent';

const AdminLayout = Loadable({
  loader: () => import('./App/layout/AdminLayout'),
  loading: Loader,
});

const store = createStore(reducer, composeWithDevTools());

const app = (
  <Provider store={store}>
    <BrowserRouter basename={config.basename}>
      <Switch>
        <Route path="/callback" component={CallbackComponent} />
        <Route path="/auth/signin-1" component={SignIn1} />
        <PrivateRoute path="/account-whitelisting" component={AdminLayout} />
        <PrivateRoute path="/" component={AdminLayout} />
      </Switch>
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
