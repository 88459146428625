import React from 'react';

import '../../../assets/scss/style.scss';
import PropTypes from 'prop-types';
import Aux from '../../../hoc/_Aux';
import Breadcrumb from '../../../App/layout/AdminLayout/Breadcrumb';
import Auth from '../../../auth/Auth';

class SignIn1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
  }

  onKeyPress(e) {
    const { email, password } = this.state;
    if (e.key === 'Enter') {
      Auth.signIn(email, password);
    }
  }

  handleInputChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  render() {
    const { location } = this.props;
    const search = new URLSearchParams(location.search);
    return (
      <Aux>
        <Breadcrumb />
        <div className="auth-wrapper">
          <div className="auth-content">
            <div className="auth-bg">
              <span className="r" />
              <span className="r s" />
              <span className="r s" />
              <span className="r" />
            </div>
            <div className="card">
              <div className="card-body text-center">
                <div className="mb-4">
                  <i className="feather icon-unlock auth-icon" />
                </div>
                <div className="text-center" style={{ color: 'red' }}>{search.get('message')}</div>
                <h3 className="mb-4">Sign in with Gmail</h3>
                <button
                  type="button"
                  className="btn btn-primary shadow-2 mb-4"
                  onClick={() => Auth.signInGoogleSocialLogin()}
                >
                  Check in
                </button>
              </div>
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}

SignIn1.propTypes = {
  location: PropTypes.string,
};


export default SignIn1;
