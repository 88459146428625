let config = {};  // eslint-disable-line
try {
  const environment = process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV;
  console.log('REACT_APP_NODE_ENV: ', environment);
  config = require(`./${environment}`); // eslint-disable-line
} catch (e) {
  console.log(e);
  config = require('./development'); // eslint-disable-line
}

export default config;
