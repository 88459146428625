import 'es6-promise/auto';
import auth0 from 'auth0-js';
import clientConfig from '../config/config';

const config = clientConfig.default;

class Auth {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      domain: config.AUTH0.domain,
      clientID: config.AUTH0.audience,
      redirectUri: config.AUTH0.redirectUri,
      audience: `https://${config.AUTH0.domain}/api/v2/`,
      responseType: 'token id_token',
      scope: 'openid email read:current_user update:current_user_metadata',
    });
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.signOut = this.signOut.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.setSession = this.setSession.bind(this);
    this.scheduleRenewal = this.scheduleRenewal.bind(this);
    this.clearRenewalTimeout = this.clearRenewalTimeout.bind(this);

    this.tokenRenewalTimeout = null;
    window.addEventListener('load', () => {
      this.scheduleRenewal();
    });
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) return reject(err);

        if (!authResult || !authResult.idToken) {
          return reject(err);
        }
        this.setSession(authResult);
        return resolve();
      });
    });
  }

  signInGoogleSocialLogin() {
    this.auth0.authorize({
      connection: 'google-oauth2',
      redirectUri: config.AUTH0.redirectUri,
    });
  }

  signOut() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('profile');
    localStorage.removeItem('expires_at');

    this.clearRenewalTimeout();
    this.auth0.logout({
      clientID: config.AUTH0.audience,
      returnTo: config.AUTH0.logoutUrl,
    });
  }


  /* eslint-disable */
  getIdToken() {
    return localStorage.getItem('id_token');
  }

  isAuthenticated() {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }
  /* eslint-enable */

  setSession(authResult) {
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('profile', JSON.stringify(authResult.idTokenPayload));
    localStorage.setItem('expires_at', expiresAt);
    this.clearRenewalTimeout();
    this.scheduleRenewal();
  }

  scheduleRenewal() {
    if (!this.tokenRenewalTimeout) {
      const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
      const delay = expiresAt - Date.now();
      if (delay > 0) {
        this.tokenRenewalTimeout = setTimeout(() => this.silentAuth(), delay);
      }
    }
  }

  silentAuth() {
    console.log('silent authing');
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult) => {
        if (err) return reject(err);
        this.setSession(authResult);
        return resolve();
      });
    });
  }

  clearRenewalTimeout() {
    clearTimeout(this.tokenRenewalTimeout);
    this.tokenRenewalTimeout = null;
  }

  /* eslint-disable */
   getProfileInfo(key) {
    const profile = localStorage.getItem('profile');

    if (!profile) {
      console.log('No profile info found');
      return;
    }

    const profileJson = JSON.parse(profile);
    if (key in profileJson) {
      return profileJson[key];
    }
    return profileJson[`${config.AUTH0.namespace}${key}`];
  }
}

const auth0Client = new Auth();

export default auth0Client;
