import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import config from '../../../../config';
import navigation from '../../../../menu-items';
import DEMO from '../../../../store/constant';
import Aux from '../../../../hoc/_Aux';

class Breadcrumb extends Component {
    state = {
      main: [],
      item: [],
    };

    componentDidMount() {
      (navigation.items).map((item, index) => {
        if (item.type && item.type === 'group') {
          this.getCollapse(item, index);
        }
        return false;
      });
    }

    componentWillReceiveProps = () => {
      (navigation.items).map((item) => {
        if (item.type && item.type === 'group') {
          this.getCollapse(item);
        }
        return false;
      });
    };

    getCollapse = (item) => {
      if (item.children) {
        (item.children).filter((collapse) => {
          if (collapse.type && collapse.type === 'collapse') {
            this.getCollapse(collapse);
          } else if (collapse.type && collapse.type === 'item') {
            if (document.location.pathname === config.basename + collapse.url) {
              this.setState({ item: collapse, main: item });
            }
          }
          return false;
        });
      }
    };

    render() {
      let mainStuff;
      let itemStuff;
      let breadcrumb = '';
      let documentTitle = 'Welcome';
      const { main, item } = this.state;
      if (main && main.type === 'collapse') {
        mainStuff = (
          <li className="breadcrumb-item">
            <a href={DEMO.BLANK_LINK}>{main.title}</a>
          </li>
        );
      }

      if (item && item.type === 'item') {
        documentTitle = item.title;
        itemStuff = (
          <li className="breadcrumb-item">
            <a href={DEMO.BLANK_LINK}>{documentTitle}</a>
          </li>
        );

        if (item.breadcrumbs !== false) {
          breadcrumb = (
            <div className="page-header">
              <div className="page-block">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <div className="page-header-title">
                      <h5 className="m-b-10">{documentTitle}</h5>
                    </div>
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/"><i className="feather icon-home" /></Link>
                      </li>
                      {mainStuff}
                      {itemStuff}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      }

      document.title = `${documentTitle} | Interviewer.AI Admin`;

      return (
        <Aux>
          {breadcrumb}
        </Aux>
      );
    }
}

export default Breadcrumb;
