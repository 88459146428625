const devURL = `http://${window.location.host}`;
const config = {
  AUTH0: {
    audience: 'h0a0v5k5nF4LeMXG5cVPkt1mcr4NURwi',
    domain: 'dev-interviewer-ai-admin-dashboard.us.auth0.com',
    redirectUri: `${devURL}/callback`,
    logoutUrl: devURL,
  },
  TRIAL_PLAN: {
    BRONZE_TRIAL: 15,
    ENTERPRISE_TRIAL: 16,
  },
  PRICING_PLAN: {
    BRONZE: 12,
    SILVER: 13,
    GOLD: 14,
    BRONZE_TRIAL: 15,
    ENTERPRISE_TRIAL: 16,
  },
};

export default config;
