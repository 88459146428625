import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Auth from './auth/Auth';

const PrivateRoute = ({ component: Component, componentProps, ...rest }) => ( // eslint-disable-line
  <Route
    {...rest}
    render={props => (Auth.isAuthenticated()
      ? (
        <Component {...componentProps} {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/auth/signin-1',
          }}
        />
      ))
    }
  />
);

export default PrivateRoute;
