const config = {
  AUTH0: {
    audience: 'sXm2OelTfsZ8EbTz9Tb8IyI8J0g1KQE5',
    domain: 'prod-interviewer-ai-admin-dashboard.us.auth0.com',
    redirectUri: 'http://admin.interviewer.ai/callback',
    logoutUrl: 'http://admin.interviewer.ai',
  },
  TRIAL_PLAN: {
    BRONZE_TRIAL: 45,
    ENTERPRISE_TRIAL: 46,
  },
  PRICING_PLAN: {
    BRONZE: 42,
    SILVER: 43,
    GOLD: 44,
    BRONZE_TRIAL: 45,
    ENTERPRISE_TRIAL: 46,
  },
};

export default config;
