import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import LoadingComponent from './LoadingComponent';
import auth0Client from '../../auth/Auth';

class Callback extends Component {
  async componentDidMount() {
    try {
      await auth0Client.handleAuthentication();
      const { history } = this.props;
      history.replace('/account-whitelisting');
    } catch (e) {
      const { history } = this.props;
      history.replace(`/auth/signin-1/?message=${e.errorDescription || 'Access Denied'}`);
    }
  }

  render() {
    return (
      <LoadingComponent />
    );
  }
}

Callback.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(Callback);
